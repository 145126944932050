import * as React from 'react'
import { Link } from 'gatsby'
import '@deque/cauldron-styles'
import { Helmet } from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import createDocumentTitle from '../lib/document-title'
import Header from '../components/Header'
import Footer from '../components/Footer'
import classNames from 'classnames'
import {
  Card,
  CardContent,
  CardHeader,
  Link as ExternalLink,
} from '@deque/cauldron-react'
import SiteSearch from '../components/Search/SiteSearch'
import './index.scss'
import Logo from '../components/Header/Logo'
import ContactUs from '../components/Header/ContactUs'

declare const PREVIEW: boolean

export default function Home() {
  const { title, contentPackages } = useSiteMetadata()

  return (
    <>
      <Helmet>
        <title>{createDocumentTitle(title)}</title>
        <meta name="description" content="Deque product documentation" />
      </Helmet>
      <div className="Home">
        <div className="Home__prism"></div>

        <Header centerAlign>
          <div className="cluster--left">
            <Logo />
          </div>
          <div className="cluster--right">
            <SiteSearch />
            <ContactUs />
          </div>
        </Header>

        <div
          className={classNames(['Home__content', 'center-align'])}
          role="main"
        >
          <h1 className="Offscreen">Deque Docs</h1>
          <div className="Home__products">
            <h2>Product Documentation</h2>
            <div className="Home__product-grid">
              {contentPackages
                .filter(({ includeInMainNav }) => {
                  // Show cards for _all_ products in a preview environment
                  if (PREVIEW) {
                    return true
                  }
                  return includeInMainNav
                })
                .map(({ id, name, description, url }) => {
                  const href = url ? url : `/${id}`
                  return (
                    <a href={href} key={id}>
                      <Card variant="simple">
                        <CardHeader>
                          <h3>
                            <span>{name}</span>
                            {url && (
                              <>
                                <i className="fas fa-external-link-alt"></i>
                                <span className="Offscreen">
                                  (links to an external site)
                                </span>
                              </>
                            )}
                          </h3>
                        </CardHeader>
                        <CardContent>
                          <p>{description}</p>
                        </CardContent>
                      </Card>
                    </a>
                  )
                })}
            </div>
          </div>
          <div className="Home__knowledge-base">
            <h2>Have a Specific Question?</h2>
            <p>
              Visit the Deque Help center to browse articles in the Knowledge
              Base or submit a support request.
            </p>
            <p>
              <ExternalLink
                href="https://www.deque.com/help-center/"
                variant="button"
              >
                Visit the Help Center
              </ExternalLink>
            </p>
          </div>
          <p className="Offscreen" aria-hidden="true">
            <Link to={`/__deque/pages`} tabIndex={-1}>
              Pages
            </Link>
          </p>
        </div>
        <Footer centerAlign />
      </div>
    </>
  )
}
